<template>
    <div class="login-wrap">
        <div class="content">
            <div class="login-form">
                <div class="title">
                    <p style="font-size: 18px;font-weight:bold;">{{ appName }}</p>
                </div>
                <el-form :model="loginData" label-width="68px" :rules="rules" ref="loginData">
                    <el-form-item prop="username">
                        <el-input v-model="loginData.username" @keyup.enter.native="loginSubmitForm('loginData')"
                                  placeholder="请输入用户名">
                            <i slot="prefix" class="iconfont">&#xe635;</i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="loginData.password" show-password placeholder="请输入密码" type="password"
                                  @keyup.enter.native="loginSubmitForm('loginData')">
                            <i slot="prefix" class="iconfont">&#xe614;</i>
                        </el-input>
                    </el-form-item>
                    <el-button type="primary" class="admin-btn" @click="loginSubmitForm('loginData')">登录</el-button>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from "@/utils/config";
    export default {
        name: 'Login',
        data() {
            return {
                appName: config.app_name,
                loginData: {
                    'username': '',
                    'password': ''
                },
                rules: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40个字符', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 6, max: 25, message: '长度在 6 到 25个字符', trigger: 'blur'}
                    ]
                },
            }
        },
        methods: {
            loginSubmitForm(fromName) {
                // console.log(52,fromName)
                this.$refs[fromName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('username', this.loginData.username);
                        formData.append('password', this.loginData.password);
                        this.$httpteacher.axiosPost(this.$api.login, formData, (res) => {
                            if (res.code == 200) {
                                if (res.data.role === 5) {
                                    this.$message({
                                        type: 'success',
                                        message: '企业登录成功！',
                                        duration: 1000,
                                    })
                                    localStorage.setItem('companyToken', res.data.token);
                                    localStorage.setItem('username', res.data.username);
                                    localStorage.setItem('adminIcon', res.data.head_portrait);
                                    localStorage.setItem('defaultActive', '/company/StuExpert/index');
                                    this.$router.push('/company/StuExpert/index');
                                    this.username = "";
                                    this.password = ""
                                }
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.message
                                });
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        return false
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .login-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/image/company-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        overflow: auto;
    }

    .header {
        padding: 20px;
    }

    .content {
        width: 960px;
        height: 520px;
        margin: 40px auto;
    }

    .el-input {
        width: 340px;
        border: 1px solid rgba(177, 179, 204, 1);
        border-radius: 4px
    }

    .login-form {
        width: 480px;
        height: 400px;
        margin: 0 auto;
        background: #FFFFFF;
        border-radius: 20px;

        ::v-deep .el-input__inner {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #DCDFE6;
            border-radius: 4px
        }

        ::v-deep .el-form-item__content {
            margin-left: 30px !important;
        }

        i {
            font-size: 20px;
        }
    }

    ::v-deep .el-form-item {
        margin-bottom: 30px;
    }

    .el-form {
        background: #FFFFFF;
        margin-left: 35px;
    }

    .title {
        margin-bottom: 30px;
        text-align: center;
        padding-top: 16px;
        margin-right: 50px;
    }

    .title span {
        font-size: 30px;
        color: #5794FF;
        margin-left: 30px;
    }

    .title p {
        color: #333333;
        font-size: 18px;
        font-weight: 400;
        margin-left: 30px;
    }

    .admin-btn {
        width: 340px;
        /*margin-left: 68px;*/
        margin-top: 30px;
        background: rgba(87, 148, 255, 1);
        border-radius: 20px;
        border: none;
        margin-left: 30px;
    }
</style>
